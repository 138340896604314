import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, Table, TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
import { MRT_GlobalFilterTextField } from "material-react-table";
import DownloadIcon from "@mui/icons-material/Download";
import { BaseTable } from "../BaseTable";
import { CSVLink } from "react-csv";
import { CopyableCell, CurrentDate } from "../../helpers";
import { useParams } from "react-router-dom";
import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import { FormatDateTime } from "../../helpers/FormatDateTime";
const AccessLogTable = ({ data, setTotalData, dataLoading }) => {
  const { tenant } = useParams();
  useEffect(() => {
    if (Array.isArray(data)) {
      setTotalData({ before: data.length, after: data.length });
    }
  }, [data, setTotalData]);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(JSON.stringify(text.original));
  };

  const DetailPanel = ({ row }) => (
    <Box sx={{ margin: "10px" }} className="access-log-sub-wrapper">
      <Table size="small" aria-label="details">
        <TableBody>
          {[
            { label: "SESSION ID", value: row.original.sessionId || "N/A" },
            { label: "USER ID", value: row.original.userId || "N/A" },
            { label: "REDIRECT URL", value: row.original.redirectUri || "N/A" },
            { label: "GRANT TYPE", value: row.original.grantType || "N/A" },
            { label: "REFRESH TOKEN TYPE", value: row.original.refreshTokenType || "N/A" },
            { label: "SCOPE", value: row.original.scope || "N/A" },
          ].map((item, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row" sx={{ fontWeight: "bold", width: "25%" }}>
                {item.label}
              </TableCell>
              <TableCell>
                <Tooltip title="Click to copy row data">
                  <span
                    style={{ cursor: "copy", display: "flex", alignItems: "center" }}
                    onClick={() => handleCopy(row)}
                  >
                    {item.value}
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );

  const currentDate = CurrentDate();
  const columns = useMemo(
    () => [
      {
        accessorKey: "time",
        header: "TIME",
        size: 70,
        Cell: ({ cell, row }) => {
          return (
            <Tooltip title="Click to copy row data">
              <span style={{ cursor: "copy", display: "flex", alignItems: "center" }} onClick={() => handleCopy(row)}>
                <div className="padding-lr-10"> {FormatDateTime(cell.getValue())}</div>
              </span>
            </Tooltip>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const a = new Date(rowA.getValue(columnId)).getTime();
          const b = new Date(rowB.getValue(columnId)).getTime();
          return a < b ? -1 : a > b ? 1 : 0;
        },
      },
      {
        accessorKey: "type",
        header: "TYPE",
        size: 50,
        Cell: ({ cell, row }) => {
          return <CopyableCell cell={cell} row={row} handleCopy={handleCopy} />;
        },
      },
      {
        accessorKey: "clientId",
        header: "CLIENT ID",
        size: 100,
        Cell: ({ cell, row }) => {
          return <CopyableCell cell={cell} row={row} handleCopy={handleCopy} />;
        },
      },
      {
        accessorKey: "userName",
        header: "USER NAME",
        size: 70,
        Cell: ({ cell, row }) => {
          return <CopyableCell cell={cell} row={row} handleCopy={handleCopy} />;
        },
      },

      {
        accessorKey: "sessionId",
        header: "SESSION ID",
        size: 0,
        Cell: ({ cell, row }) => {
          return <CopyableCell cell={cell} row={row} handleCopy={handleCopy} />;
        },
      },
      {
        accessorKey: "ipAddress",
        header: "IP ADDRESS",
        size: 50,
        Cell: ({ cell, row }) => {
          return <CopyableCell cell={cell} row={row} handleCopy={handleCopy} />;
        },
      },
      {
        accessorKey: "error",
        header: "ERROR",
        size: 70,
        Cell: ({ cell, row }) => {
          return <CopyableCell cell={cell} row={row} handleCopy={handleCopy} />;
        },
      },
      {
        accessorKey: "grantType",
        header: "grantType",
        size: 0,
      },
      {
        accessorKey: "redirectUri",
        header: "redirectUri",
        size: 0,
      },
      {
        accessorKey: "refreshTokenType",
        header: "refreshTokenType",
        size: 0,
      },
      {
        accessorKey: "scope",
        header: "scope",
        size: 0,
      },
    ],
    []
  );

  const renderTopToolbar = ({ table }) => (
    <Box className="flex_box-space">
      <Grid container className="UserListContainer">
        <Grid item xs={9}></Grid>
        <Grid item xs={2} className="global-search-textbox-wrapper">
          <MRT_GlobalFilterTextField table={table} />
        </Grid>
        {!dataLoading && Array.isArray(data) && data.length > 0 && (
          <Grid item xs={1} className="download-btn-wrapper">
            <CSVLink
              data={data.map((item) => ({
                ...item,
                time: FormatDateTime(item.time),
              }))}
              filename={`WorldlineCIAM-${tenant?.toUpperCase()}-AccessLog-Standard-${currentDate}.csv`}
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" endIcon={<DownloadIcon />} className="report-btn">
                Download
              </Button>
            </CSVLink>
          </Grid>
        )}
      </Grid>
    </Box>
  );

  const customTableOptions = {
    columns,
    data: data || [],
    renderTopToolbar,
    enableExpanding: true,
    enablePagination: true,
    paginationDisplayMode: "pages",
    enableSorting: true,
    enableColumnResizing: false,
    muiPaginationProps: {
      className: "paginationClasses",
      shape: "rounded",
      variant: "outlined",
      rowsPerPageOptions: [5, 10, 25, 50],
      showFirstButton: false,
      showLastButton: false,
    },
    renderDetailPanel: ({ row }) => <DetailPanel row={row} />,
    muiExpandButtonProps: ({ row }) => ({
      children: row.getIsExpanded() ? (
        <span className="expand-collapse-details">
          <ArrowDropDownSharpIcon fontSize="medium" className="ArrowDropDownSharpIcon" /> Hide
        </span>
      ) : (
        <span className="expand-collapse-details">
          <ArrowRightSharpIcon fontSize="medium" data-testid="ArrowRightSharpIcon" className="ArrowRightSharpIcon" />{" "}
          Show more
        </span>
      ),
    }),
    muiExpandAllButtonProps: ({ table }) => ({
      onClick: () => table.toggleAllRowsExpanded(),
      children: table.getIsAllRowsExpanded() ? (
        <span className="expand-collapse-details expand-collapse-all-details">
          <ArrowDropDownSharpIcon fontSize="medium" className="ArrowDropDownSharpIcon" /> HIDE ALL
        </span>
      ) : (
        <span className="expand-collapse-details expand-collapse-all-details">
          <ArrowRightSharpIcon fontSize="medium" data-testid="ArrowRightSharpIcon" className="ArrowRightSharpIcon" />{" "}
          SHOW ALL
        </span>
      ),
    }),
    muiTablePaperProps: ({ table }) => ({
      style: {
        top: table.getState().isFullScreen ? 100 : 0,
      },
    }),
    muiSkeletonProps: {
      animation: "wave",
    },
    initialState: {
      sorting: [
        {
          id: "time",
          desc: true,
        },
      ],
      showGlobalFilter: true,
      columnVisibility: {
        grantType: false,
        redirectUri: false,
        refreshTokenType: false,
        scope: false,
        sessionId: false,
      },
    },
    enableRowNumbers: false,
    enableColumnActions: false,
    muiTableHeadCellProps: {
      sx: {
        "& .Mui-TableHeadCell-Content-Actions": {
          display: "none",
        },
      },
    },
  };

  if (!data || data == undefined || !Array.isArray(data) || data.length === 0) {
    return <div />;
  }
  return !dataLoading && <BaseTable setTotalData={setTotalData} customTableOptions={customTableOptions} />;
};

AccessLogTable.propTypes = {
  data: PropTypes.array,
  setTotalData: PropTypes.func,
  dataLoading: PropTypes.bool,
  rawData: PropTypes.array,
  cell: PropTypes.object,
  row: PropTypes.object,
};

export default AccessLogTable;
