function UserManagerErrorNotification(error) {
  let errorMsg = "";

  if (error?.error_description && error?.error_validations) {
    errorMsg = `Error: ${error?.error_validations.map((n) => n.message)}`;
  } else if (error?.error_description) {
    errorMsg = `Error: ${error?.error_description}`;
  } else {
    errorMsg = "Unknown error";
  }
  return errorMsg;
}

export default UserManagerErrorNotification;
