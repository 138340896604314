import { useState, useEffect } from "react";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { LeftDrawerList } from "../LeftDrawerList";
import { HandleapiError, TransformJsonDataUtil } from "../../helpers";
import { useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const BaseReport = ({
  title,
  getDataService,
  tableComponent: TableComponent,
  formComponent: FormComponent,
  roleService,
  callDataSvc,
  classname,
  setCallDataSvc,
  transformData,
}) => {
  const { tenant } = useParams();
  const theme = `${tenant}-theme`;
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState({ before: 0, after: 0 });
  const [formData, setFormData] = useState({});
  const [openError, setOpenError] = useState(false);
  const fetchData = async () => {
    setData([]);
    setError(null);
    try {
      setDataLoading(true);
      const response = await getDataService(roleService, formData);
      setRawData(response?.data);
      console.log(response, "response");
      setData(transformData ? TransformJsonDataUtil(response?.data) : response?.data);
      setError(null);
    } catch (error) {
      const errorMessage = HandleapiError(error);
      setError(errorMessage);
      setOpenError(true);
    } finally {
      setDataLoading(false);
      setCallDataSvc(false);
    }
  };
  useEffect(() => {
    if (callDataSvc) {
      fetchData();
    }
  }, [callDataSvc, formData]);
  const handleFormSubmit = (newFormData) => {
    setFormData(newFormData);
    if (!callDataSvc) {
      fetchData();
    }
  };

  return (
    <div
      className={theme}
      id="base-report-wrapper"
      data-testid="base-report-wrapper"
      style={isLeftDrawerOpen ? { width: "88vw", marginLeft: "11vw" } : { width: "97vw", marginLeft: "1vw" }}
    >
      <div className="heading-bar">
        <Grid container spacing={1} className="report-container">
          <Grid item xs={12} md={4}>
            <Stack direction="row" className="report-sub-heading-wrapper">
              {!isLeftDrawerOpen && (
                <IconButton
                  edge="start"
                  onClick={() => setIsLeftDrawerOpen(true)}
                  sx={{ justifyContent: "flex-start", display: "flex" }}
                >
                  <Typography
                    component="img"
                    height="15px"
                    sx={{ position: "static" }}
                    src={process.env.PUBLIC_URL + "/static/images/drawer_menu_icon.png"}
                    className="expand-left-bar-icon"
                    alt="drawer menu icon"
                  />
                </IconButton>
              )}
              <Typography
                variant="h4"
                style={isLeftDrawerOpen ? { marginLeft: "2vw" } : { marginLeft: "20px" }}
                sx={{ fontSize: "22px", fontFamily: "Nunito", display: "flex", alignSelf: "center" }}
              >
                {title}
                {data.length > 0 && ` - ${totalData.after} ${totalData.after === 1 ? "Record" : "Records"} `}
              </Typography>
            </Stack>
          </Grid>
          <Grid md={3} sx={{ maxWidth: "20%" }} className="ErrorMessageGrid">
            {error && openError && (
              <div className="global-error-msg">
                <div className="report-error-container">
                  <div>{error}</div>
                  <div className="close-icn" onClick={() => setOpenError(false)}>
                    <CloseIcon fontSize="small" />
                  </div>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <LeftDrawerList isLeftDrawerOpen={isLeftDrawerOpen} setIsLeftDrawerOpen={setIsLeftDrawerOpen} />
      {FormComponent && <FormComponent onSubmit={handleFormSubmit} dataLoading={dataLoading} />}

      <Grid container spacing={1} className="report-table-wrapper" data-testid="report-table">
        <Grid item xs={12} className={classname}>
          <TableComponent data={data} dataLoading={dataLoading} setTotalData={setTotalData} rawData={rawData} />
        </Grid>
      </Grid>
    </div>
  );
};

BaseReport.propTypes = {
  title: PropTypes.string.isRequired,
  getDataService: PropTypes.func.isRequired,
  tableComponent: PropTypes.elementType.isRequired,
  formComponent: PropTypes.elementType,
  roleService: PropTypes.object.isRequired,
  callDataSvc: PropTypes.bool,
  classname: PropTypes.string,
  setCallDataSvc: PropTypes.func,
  transformData: PropTypes.func,
};

export default BaseReport;
