import { Drawer, ListItem, ListItemText, Box, Stack, List, Collapse } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import PropTypes from "prop-types";
import "./LeftDrawerList.css";
import { useAuth } from "../../contexts/AuthContext";
import { Roles } from "../SecApp/Roles";

function LeftDrawerList({ isLeftDrawerOpen, setIsLeftDrawerOpen }) {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Dashboard");
  const handleSubMenuClick = () => {
    setOpenSubMenu(!openSubMenu);
  };
  const navigate = useNavigate();
  const { authService } = useAuth();
  const { tenant } = useParams();
  const { id } = useParams("id");
  const dashboardURL = `/ciam/v1/${tenant}`;
  const userURL = `/ciam/v1/${tenant}/users`;
  const createUserURL = `/ciam/v1/${tenant}/users/create`;
  const editUserURL = `/ciam/v1/${tenant}/users/edit/${id}`;
  const profileURL = `/ciam/v1/${tenant}/profile`;
  const accessLogReportURL = `/ciam/v1/${tenant}/reports/access-log`;
  const roleAssignmentReportURL = `/ciam/v1/${tenant}/reports/role-assignment`;
  const userLastLoginURL = `/ciam/v1/${tenant}/reports/user-last-login`;
  const navigateTo = (path) => {
    navigate(path);
  };
  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath === dashboardURL) {
      setSelectedItem("Dashboard");
    } else if (currentPath === userURL || currentPath === createUserURL || currentPath == editUserURL) {
      setSelectedItem("Users");
    } else if (currentPath === accessLogReportURL) {
      setSelectedItem("Access Log");
    } else if (currentPath === roleAssignmentReportURL) {
      setSelectedItem("Role assignment");
    } else if (currentPath === userLastLoginURL) {
      setSelectedItem("User last login");
    } else if (currentPath === profileURL) {
      setSelectedItem("");
    }
  }, [dashboardURL, userURL, accessLogReportURL, profileURL]);

  return (
    <Box sx={{ bgcolor: "#FAFCFE" }}>
      <Drawer
        data-testid="left-drawer"
        anchor="left"
        open={isLeftDrawerOpen}
        onClose={() => isLeftDrawerOpen(false)}
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "max-content",
            overflowX: "hidden",
          },
          "& .MuiPaper-root": {
            backgroundColor: "#FAFCFE",
          },
        }}
        variant="persistent"
      >
        <nav aria-label="main mailbox folders">
          <Stack
            sx={{
              marginTop: "100px",
              fontFamily: "Calibri",
              fontSize: "16px",
              color: "#778FA5",
              "& .RaMenuItemLink-active": {
                color: "#0099FF",
                fontWeight: "bold",
              },
            }}
          >
            <ListItem sx={{ justifyContent: "flex-end", cursor: "pointer" }}>
              <ArrowBackIosNewIcon className="back-arrow-icon" onClick={() => setIsLeftDrawerOpen(false)} />
            </ListItem>
            <ListItem
              onClick={() => {
                setSelectedItem("Dashboard");
                navigateTo(dashboardURL);
                setIsLeftDrawerOpen(false);
              }}
              className="menu_list_item"
              sx={{ paddingLeft: "0px" }}
            >
              <ListItemText primary="Dashboard" className={selectedItem === "Dashboard" ? "selected" : ""} />
            </ListItem>
            {authService?.authorize([Roles.ADMIN, Roles.ADMIN_MT]) && (
              <ListItem
                onClick={() => {
                  setSelectedItem("Users");
                  setIsLeftDrawerOpen(false);
                  navigateTo(userURL);
                }}
                className="menu_list_item"
                sx={{ paddingLeft: "0px" }}
              >
                <ListItemText primary="Users" className={selectedItem == "Users" ? "selected" : ""} />
              </ListItem>
            )}
            {authService?.authorize([
              Roles.ACCESS_LOG_VIEWER,
              Roles.ACCESS_LOG_VIEWER_MT,
              Roles.ROLE_ASSIGNMENT_VIEWER,
              Roles.LAST_LOGIN_USER_VIEWER,
              Roles.PSA_LAST_LOGIN_USER_VIEWER,
            ]) && (
              <ListItem
                onClick={() => {
                  setIsLeftDrawerOpen(true);
                  handleSubMenuClick();
                }}
                className="report_list_item"
                sx={{ paddingLeft: "0px" }}
              >
                {openSubMenu ? <ExpandLess /> : <ExpandMore />}
                <ListItemText primary="Reports" />
              </ListItem>
            )}
          </Stack>

          <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
            {authService?.authorize([Roles.ACCESS_LOG_VIEWER_PSA, Roles.ACCESS_LOG_VIEWER_MT]) && (
              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={() => {
                    setSelectedItem("Access Log");
                    setIsLeftDrawerOpen(true);
                    navigateTo(accessLogReportURL);
                  }}
                >
                  <ListItemText primary="Access Log" className={`${selectedItem === "Access Log" ? "selected" : ""}`} />
                </ListItem>
              </List>
            )}
            {authService?.authorize([Roles.ROLE_ASSIGNMENT_VIEWER]) && (
              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={() => {
                    setSelectedItem("Role assignment");
                    setIsLeftDrawerOpen(true);
                    navigateTo(roleAssignmentReportURL);
                  }}
                >
                  <ListItemText
                    primary="Role assignment"
                    className={`${selectedItem === "Role assignment" ? "selected" : ""}`}
                  />
                </ListItem>
              </List>
            )}
            {authService?.authorize([Roles.LAST_LOGIN_USER_VIEWER, Roles.PSA_LAST_LOGIN_USER_VIEWER]) && (
              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={() => {
                    setSelectedItem("User last login");
                    setIsLeftDrawerOpen(true);
                    navigateTo(userLastLoginURL);
                  }}
                >
                  <ListItemText
                    primary="User last login"
                    className={`${selectedItem === "User last login" ? "selected" : ""}`}
                  />
                </ListItem>
              </List>
            )}
          </Collapse>
        </nav>
      </Drawer>
    </Box>
  );
}
LeftDrawerList.propTypes = {
  setIsLeftDrawerOpen: PropTypes.func,
  isLeftDrawerOpen: PropTypes.bool,
};
export default LeftDrawerList;
